@import '@theme/variables.scss';

.order-data {
  &__wrapper {
    padding: 50px;
    background-color: var(--color-bg-primary);
    line-height: 1.5;

    @media screen and (max-width: $breakpoint-sm) {
      padding: 25px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      font-size: var(--font-size-secondary);
    }
  }

  &__title {
    margin-top: 20px;
    font-size: 22px;
    font-family: "Gotham-Bold";

    &-wrapper {
      margin: 0 auto;
      text-align: center;
      color: #4a4a4a;
    }
  }

  &__order-id {
    font-weight: bold;
  }

  &__content {
    margin: 0 auto;
    width: 50%;

    @media screen and (max-width: $breakpoint-md) {
      width: 100%;
    }
  }

  &__table {
    margin-top: 50px;

    @media screen and (max-width: $breakpoint-sm) {
      margin-top: 20px;
    }

    &_item {
      display: flex;

      &:nth-of-type(2n) {
        background-color: #f2f2f2;

        @media screen and (max-width: $breakpoint-sm) {
          margin: 15px 0;
          padding: 5px 0;
        }

        @media screen and (max-width: $breakpoint-xs) {
          margin: 10px 0;
        }
      }

      @media screen and (max-width: $breakpoint-sm) {
        flex-direction: column;
        align-items: center;
      }
    }

    &_label {
      margin-right: 15px;
      padding-right: 15px;
      width: 175px;
      font-family: 'Gotham-Bold';
      border-right: 2px solid black;

      @media screen and (max-width: $breakpoint-md) {
        width: 150px;
      }

      @media screen and (max-width: $breakpoint-sm) {
        margin-right: 0;
        padding: 0;
        width: auto;
        border-right: none;
      }
    }
  }

  &__tickets {
    margin-top: 50px;

    &_row {
      display: flex;

      &_head {
        border-bottom: 1px solid black;
        font-style: italic;
      }

      &:nth-of-type(2n) {
        background-color: #f2f2f2;
      }
    }

    &_label {
      margin-right: 15px;
      width: 50px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid black;
      text-align: center;
    }

    &__name {
      padding-right: 15px;
    }
  }

  &__controls {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
    }
  }
  
  &__control {
    width: 150px;
    padding: 15px;
    border: none;
    color: var(--color-bg-primary);
    background-color: var(--secondary-button-hover);

    &:nth-child(2) {
      margin-left: 10px;
    }

    &:hover {
      background-color: var(--secondary-button-hover);
    }

    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
      
      &:nth-child(2) {
        margin-top: 15px;
        margin-left: 0;
      }
    }

    &_secondary {
      background-color: var(--cart-details-color);
      
      &:hover {
        background-color: var(--cart-details-color);
        cursor: pointer;
      }
    }

    &_disabled,
    &_disabled:hover { 
      background-color: var(--cart-details-color);
      cursor: pointer;
    }

    &_wrapper {
      @media screen and (max-width: $breakpoint-sm) {
        width: 100%;
      }
    }
  }

  &__terms {
    margin-bottom: 20px;

    &_label {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;

      &:hover {
        cursor: pointer;
      }
    }

    &_control {
      margin-right: 10px;
      width: 15px;
      height: 15px;
      flex-shrink: 0;
    }
  }

  &__error {
    margin-top: 25px;
    text-align: left;
  }

  &__success {
    text-align: center;
    font-size: 20px;
    color: #4a4a4a;
  }

  &__close {
    margin: 25px auto 0;
    padding: 15px;
    width: 40%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--cart-checkout-button);
    color: var(--color-bg-primary);
    border: none;
    outline: none;
    font-size: 14px;
    font-family: 'Gotham-Book';
    text-decoration: none;

    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }

  &__link {
    color: #1176bc;
  }
}