@import './variables.scss';

@font-face {
  font-family: 'Gotham-Book';
  src: local('Gotham-Book'), url('./Gotham-Book.woff') format('woff');
}

@font-face {
  font-family: 'Gotham-Bold';
  src: local('Gotham-Bold'), url('./Gotham-Bold.woff') format('woff');
}

@font-face {
  font-family: 'BrandonGrotesque-Regular';
  src: local('BrandonGrotesque-Regular'), url('./BrandonGrotesque-Regular.woff') format('woff');
}

@font-face {
  font-family: 'BrandonGrotesque-Bold';
  src: local('BrandonGrotesque-Bold'), url('./BrandonGrotesque-Bold.woff') format('woff');
}

#root {
  background: var(--background);
  font-family: var(--font-regular);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.d-flex {
  display: flex;

  &--center--center {
    @extend .d-flex;
    justify-content: center;
    align-items: center;
  }

  &--sb--center {
    @extend .d-flex;
    justify-content: space-between;
    align-items: center;
  }

  &--fe--center {
    @extend .d-flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.container {
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;

  @media screen and (max-width: $breakpoint-sm) {
    padding: 0 10px;
  }
}

@keyframes swipe-from-right {
  from { width: 100%; }
  to { width: calc(100% - #{$small-cell-width-mobile} * 2); }
}

@keyframes show-from-right {
  from { width: 30px; }
  to { width: $small-cell-width-mobile; }
}

@keyframes show-from-right-tablet {
  from { width: 30px; }
  to { width: $small-cell-width * 2; }
}

@keyframes show-from-right-mobile {
  from { width: 30px; }
  to { width: $small-cell-width-mobile * 2; }
}

body > img {
  display: none;
}
