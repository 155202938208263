@import '@theme/variables.scss';

.loader {
  width: 30px;
  height: 30px;
  background-color: var(--color-bg-cta);
  margin: auto;
  animation: rotateplane 1.2s infinite ease-in-out;

  &-wrapper {
    position: relative;
    left: 0;
    top: 0;
    height: 35px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;

    &__blured {
      background-color: rgba(255, 255, 255, 0.9);
    }

    &__full-height {
      height: 100%;
    }

    &__full-width {
      width: 100%;
    }

    &__absolute {
      position: absolute;
    }

    &__fixed {
      z-index: 1000;
      position: fixed;
      flex-direction: column;
      padding: 0 25px;
      box-sizing: border-box;

      @media screen and (max-width: $breakpoint-sm) {
        height: 100%;
        padding: 0;
      }
    }
  }

  &__content {
    margin: 25px 0;
    padding: 25px;
    width: 100%;
    max-width: 600px;
    font-size: 22px;
    text-align: center;
    background-color: var(--color-bg-cta);
    box-sizing: border-box;

    @media screen and (max-width: $breakpoint-sm) {
      margin: 0;
      padding: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__candy {
    display: block;
    position: relative;
    width: 100%;
    height: 25px;
    background-color: #73C9E3;
    overflow: hidden;

    &::after {
      position: absolute;
      animation: load 1s infinite;
      content: "";
      top: 0px;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
      z-index: 1;
      background-size: 50px 50px;
    }

    @media screen and (max-width: $breakpoint-sm) {
      width: 67%;
    }
  }

  &__title {
    margin-bottom: 20px;
    font-size: 22px;
    color: var(--color-bg-primary);
  }
}

@keyframes rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }

  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }

  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

@keyframes candy-loader {
  0% {
    background-position: 40px 0;
  }

  100% {
    background-position: 0 0;
  }
}

@keyframes load {
  0% {
    background-position: 50px 0;
  }
  100% {
    background-position: 0 0;
  }
}